<template>
  <div id="tutorial">
    <MyheadCompnent></MyheadCompnent>
    <el-container>
      <el-aside width="300px">
        <div class="left-menu">
          <ul ref="ul" id="uu">
            <a @click="changeHash('#t',0)" class='link'>
              <p class='link-text'>An overview of PMhub</p>
            </a>
            <a @click="changeHash('#t1',0)" class='link'>
              <p class='link-text'>1. Introduction</p>
            </a>
            <a @click="changeHash('#t2',0)" class='link'>
              <p class='link-text'>2. The workflow and primary content of PMhub</p>
            </a>
            <a @click="changeHash('#t3',1)" class='link'>
              <p class='link-text'>3. Metabolite identification</p>
            </a>
            <a @click="changeHash('#t4',2)" class='link'>
              <p class='link-text'>4. LOC search</p>
            </a>
            <a @click="changeHash('#t5',2)" class='link'>
              <p class='link-text'>5. Feature details</p>
            </a>
          </ul>
        </div>
      </el-aside>
      <el-scrollbar height="750px">
        <el-main>
          <div id="t">
            <div>
              <h1>An overview of PMhub</h1>
            </div>
          </div>
          <div id="t1">
            <div>
              <h2>1. Introduction</h2>
              <p>The Plant Metabolome Hub (PMhub), available at <a
                  :href="$store.state.frontbaseURL">https://pmhub.org.cn</a>, is a valuable resource designed to
                provide scientists with comprehensive information on plant metabolites. It offers extensive details
                about their reference spectra, genetic foundations, chemical reactions, metabolic pathways, and
                biological functions. The PMhub includes chemical data for 188,837 plant metabolites gathered from
                various sources, with 1,409,947 standard/<i>in-silico</i> high-resolution tandem mass spectrometry
                (HRMS/MS) corresponding to these metabolites. Beyond its extensive literature-derived data, the PMhub
                also boasts a sizable collection of experimental metabolites, it contains 144,366 detected features in
                ten typical plant species, with 16,423 successfully annotated by using standard/<i>in-silico</i>
                HRMS/MS, this
                collection is further supplemented with thousands of features gathered from reference metabolites. For
                each metabolite, the PMhub enables the reconstructed of a simulated network based on structural
                similarities and existing metabolic pathways. Metabolites with profiling data, along with their
                corresponding genomic and/or transcriptomic information, are also compiled for genetic analysis. When
                combined with the simulated network, researchers can automatically filter candidate genes for subsequent
                biological function studies. The robust functionality provided by the PMhub makes it an indispensable
                tool for scientists studying plant metabolomics.

              </p>
              <h3>
                Citing PMhub:
              </h3>
              <p>
                Tian Z, Hu X, Xu Y, Liu M, Liu H, Li D, Hu L, Wei G, Chen W. PMhub 1.0: a comprehensive plant metabolome
                database. Nucleic Acids Res. 2024 Jan 5;52(D1):D1579-D1587. <a
                  href="https://pubmed.ncbi.nlm.nih.gov/37819039/">37819039</a>
              </p>
            </div>
          </div>
          <div id="t2">
            <h2>2. The workflow and primary content of PMhub</h2>
            <div class="imgstyle">
              <!-- <img src="@/assets/img/Figure1.svg" width="800"> -->
              <img src="@/assets/img/Figure1workflow.png" width="800">
            </div>
          </div>
          <div id="t3">
            <div>
              <h2>3. Metabolite identification</h2>
            </div>
            <div>
              <h3>MS/MS search algorithm</h3>
              <p>
                A modified Dot-product function is used to score the similarity between the experimental spectrum and
                the standard spectrum in the library.The DP score ranges from 0 to 1, from no match to a perfect match.
              </p>
              <div class="imgstyle">
                <img src="@/assets/img/math.svg" width="500">
              </div>
              <h3>Individual metabolite identification</h3>
              <p><a :href="$store.state.frontbaseURL+'search/MetaboliteIdentification'">Metabolite identification</a> is
                conducted by comparing the query MS/MS to the experimental MS/MS library
                and the <i>in-silico</i> MS/MS library.</p>
              <p>The candidate metabolites are list in the right table.</p>

              <div class="imgstyle">
                <img src="@/assets/img/individual.png" width="1500">
              </div>

            </div>
            <div>
              <h3>Batch metabolite identification</h3>
              <p>Batch metabolite identification need your log-in. If there is no count, you need to register with a
                valid email. After job submission, you can check the job state and download the results in your user
                interface.</p>
              <div class="imgstyle">
                <img src="@/assets/img/batch.png" width="500">
              </div>
              <p>The user interface exhibits the historical records and results</p>
              <div class="imgstyle">
                <img src="@/assets/img/batch2.png" width="800">
              </div>
            </div>
          </div>
          <div id="t4">
            <div>
              <h2>4. LOC search</h2>
              <p><a :href="$store.state.frontbaseURL+'locsearch'">LOC search</a>
                can provide the mGWAS results for the target
                intervel of the genome of the target crop,
                such as Triticum aestivum (Chinese Spring), Zea mays (B73), Oryza sativa (Nipponbare), Lycopersicon
                esculentum (Heizn 1706).</p>
            </div>
            <div class="imgstyle">
              <img src="@/assets/img/loc_search.png" width="800">
            </div>
          </div>
          <div id="t5">
            <div>
              <h2>5. Feature details</h2>
              <p><a :href="$store.state.frontbaseURL+'featuredetail/zema_n0176'">Feature details</a> can provide
                metabolite
                identification results, the mGWAS
                results for a selected MS/MS in <a :href="$store.state.frontbaseURL+'featurebrowse'">a MS/MS list</a>.
              </p>
            </div>
            <div>
              <h4>
                5.1 MS/MS informations
              </h4>
            </div>
            <div class="imgstyle">
              <img src="@/assets/img/featuredetail1.png" width="800">
            </div>
            <div>
              <h4>
                5.2 MS/MS comparison
              </h4>
              <p>
                You need to select a candidate metabolite according to the match score by comparing the query MS/MS to
                the library MS/MS.
              </p>
            </div>
            <div class="imgstyle">
              <img src="@/assets/img/featuredetail2.png" width="800">
            </div>
            <div>
              <h4>
                5.3 network analysis
              </h4>
              <p>
                You need to select a candidate metabolite according to the match score by comparing the query MS/MS to
                the library MS/MS. 1, The nodes connected to the selected metabolite within two steps or three steps are
                shown in this 'network plot'. 2, the molecular structures of the selected edge or node. 3, the candidate
                genes are list according to the mGWAS results and Network analysis.
              </p>
            </div>
            <div class="imgstyle">
              <img src="@/assets/img/featuredetail3.png" width="800">
            </div>
            <div>
              <h4>
                5.4 GWAS details
              </h4>
              <p>
                A genome-wide association study (abbreviated GWAS) is a research approach used to identify genomic
                variants that are statistically associated with a particular trait. Once such
                genomic variants are identified, they are typically used to search for nearby variants that contribute
                directly to the trait.
              </p>
              <p>
                The metabolome-based GWAS (mGWAS) for <a :href="this.$store.state.articleUrlDict['trae']"
                  style="text-decoration: none;">Triticum aestivum</a>, <a
                  :href="this.$store.state.articleUrlDict['zema']" style="text-decoration: none;">Zea mays</a>, <a
                  :href="this.$store.state.articleUrlDict['orsa']" style="text-decoration: none;">Oryza sativa</a>, <a
                  :href="this.$store.state.articleUrlDict['lyes']" style="text-decoration: none;">Lycopersicon
                  esculentum</a> were conducted for 3 replicates. For each replicate,
                a panel of nature individuals were used to investigate the associations between metabolite contents and
                genetic variations by a linear mixed model (LMM) using the <a
                  href="https://www.microsoft.com/en-us/research/project/fastlmm/"
                  style="text-decoration: none;">FaST-LMM</a> program. The detail experimental
                procedures can be view in original article.
              </p>
              <p>
                The mGWAS details in PMhub are shown below. 1, You need to select the biological replicates, intervel
                size and 2,
                select a lead SNP according to the manhaton plot before further analysis. 3, Histogram of the frequency
                distribution of metabolite contents.4, All the genes within the
                selected intervel are shown. 5, The expression of each selected gene are ploted. 6, In each intervel,
                the candidate genes connected to the target metabolite within three steps are shown.
              </p>
            </div>
            <div class="imgstyle">
              <img src="@/assets/img/featuredetail4.png" width="800">
            </div>
          </div>
        </el-main>
      </el-scrollbar>
    </el-container>
  </div>
</template>

<script>

  import MyheadCompnent from '@/components/Common/MyheadCompnent.vue'
  import MyfooterCompnent from '@/components/Common/MyfooterCompnent.vue'
  export default {
    name: 'Tutorial',
    data () {
      return {
      }
    },
    components: {
      MyheadCompnent,
      MyfooterCompnent,
    },
    methods: {
      changeHash (val, index) {
        document.querySelector(val).scrollIntoView({
          block: 'start',
          behavior: 'smooth'
        });
      }
    }
  }
</script>

<style scoped lang="scss">
  .link {
    padding: 10px 16px;
    line-height: 1.5;
    font-size: .9rem;
    border-radius: 8px;
  }

  .link-text {
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    line-height: 1;
    font-size: 1rem;

    cursor: pointer;
  }

  .link-text:hover {
    background-color: #ffd5b2;
  }

  .el-main {
    margin-right: 40px;
  }

  .imgstyle {
    text-align: center
  }

  h1 {
    text-align: center
  }

  p {
    font-size: 22px;
  }

  .plink {
    word-wrap: break-word;
    word-break: break-all;
  }
</style>